$orange: #ffa800;
$orange6: #ff6200;
$orange2: #ffe1a6;
$orange3: #d9962b;
$orange4: #f6c576;
$orange5: #fff0d9;
$blue1: #0e518c;
$blue2: #2b88d8;
$blue3: #dbeeff;

$white: #ffffff;
$black: #000000;
$dark: #0000001e;
$green: #39bb24;
$green1: #34d919;
$green2: #d4ffcd;
$red: #ff0000;
$red2: #ffc9c9;
$line: #f0f0f0;
$line2: #dbd8d8;
$inactive: #8e8e8e;
$background: #f8f8f8;
$bg-gray: #d1cccccc;
$text: #2d2d2d;
$text2: #757575;
$text3: #8e8e8e;
$purple: #8f00ff;
$yellow: #ffeb3b;
$lightGrey: #f0f0f0;
$brown: #800000;
$light2: rgba(0, 0, 0, 0.05);

$width-sidebar: 100px;

$lg: 1280px;
$xl: 1366px;
$xxl: 1440px;
$small-laptop: 1280px;

.bg-green {
  background: $green;
}

.bg-green-2 {
  background: $green2;
}

.bg-orange {
  background: $orange;
}

.bg-orange-2 {
  background: $orange2;
}

.bg-red {
  background: $red;
}

.bg-red-1 {
  background: #ffc9c9;
}

.bg-grey {
  background: $background;
}
.bg-white {
  background: $white !important;
}

.bg-color-white {
  background-color: $white !important;
}
.bg-light {
  background: $light2;
}
.bg-gray {
  background: $text3;
}
.bg-dark {
  background: $dark;
}
.color-text {
  color: $text;
}

.color-text2 {
  color: $text2;
}

.color-green {
  color: $green;
}

.color-purple {
  color: $purple;
}

.color-orange {
  color: $orange;
}
.color-yellow {
  color: $yellow;
}

.color-blue {
  color: $blue2;
}

.color-red {
  color: $red;
}

.color-gray {
  color: $inactive;
}
.color-grey {
  color: $inactive;
}

.color-brown {
  color: $brown;
}

.text-right {
  text-align: right;
}

.color-orange-6 {
  color: $orange6 !important;
}

.text-13 {
  font-size: 13px;
}

.text-16 {
  font-size: 16px;
}

.text-faded {
  color: #c4c4c4;
}
