@import 'styles/variables.scss';

.modalEmailVendor {
  max-width: 690px;
  width: 100% !important;
}

.pModal {
  :global {
    .ant-modal-body {
      padding: 50px 75px;
    }
  }
}

.boxAvatar {
  margin: 50px 0 30px;
  :global {
    .ant-upload-list {
      display: none;
    }
  }
}

.title {
  margin: 30px 0;
  text-align: center;
}

.header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .headerLogo {
    margin-bottom: 10px;
  }
  .headerTitle {
    font-size: 30px;
    width: fit-content;
    color: #0e518c;
    span {
      color: $blue2;
    }
  }
  .formContent {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
  }
}

.headerName {
  font-size: 30px;
  padding-top: 40px;
  padding-bottom: 20px;
  margin: 0;
  font-weight: bold;
  line-height: 16px;
  color: $text !important;
  background-color: transparent !important;
  width: 100%;
  text-align: center;
  display: block;
}
.btnWhite {
  width: 100%;
  margin-top: 15px;
  height: 40px;
}

.btnSubmit {
  width: 100%;
  margin-top: 15px;
  height: 40px;
  color: white;
  background-color: $blue2;
}

.btnGoBack {
  margin-top: 20px;
  width: 48%;
}

.addCompanyButton {
  border: none;
  display: block;
  text-align: center;
  color: $blue2;
  margin: 15px auto;
  img {
    margin-right: 10px;
  }
}

.rotate {
  height: 150px;
}

.add {
  margin-top: -150px !important;
}
.time {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  margin: -60px 0 0 -60px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.spin {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100vh;
  top: 50%;
}

.or {
  width: 100%;
  text-align: center;
  display: block;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 575px) {
  .modalEmailVendor,
  .signupCompany {
    background: white;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
  }
  .pModal {
    :global {
      .ant-modal-content {
        box-shadow: none;
        -webkit-box-shadow: none;
      }
      .ant-modal-body {
        padding: 20px 20px;
        max-height: 100%;
      }
      // .ant-form {
      //   display: none;
      // }
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .modalEmailVendor,
  .signupCompany {
    background: white;
    padding: 0;
    margin: 0;
    height: 100%;
  }
  .pModal {
    :global {
      .ant-modal-content {
        box-shadow: none;
        -webkit-box-shadow: none;
      }
      .ant-modal-body {
        padding: 50px 20px;
        max-height: 100%;
      }
      // .ant-form {
      //   display: none;
      // }
    }
  }
}
