@import '../../styles/variables.scss';
.headerWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 65px;
  background-color: $blue2;
  padding: 0 10px;
  color: $white;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.logo {
  display: block;
  font-weight: bold;
  color: $white !important;
  background-color: $blue2;
  text-align: center;
  margin-left: 15px;
  img {
    width: 25px;
  }
  span {
    color: $white;
    font-size: 10px;
    display: block;
    font-weight: lighter;
    padding-top: 3px;
  }
}
.companyName {
  margin-left: 30px;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}
.menuWrapper {
  min-width: 180px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .menuItem {
    padding: 10px;
    padding-left: 20px;
    position: relative;

    .badge {
      position: absolute;
      top: 8px;
      right: 4px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      background-origin: padding-box;
      color: $white;
      min-width: 14px;
      height: 14px;
      background-color: #eb5757;
      border-radius: 50%;
    }
    &:last-child img {
      border-radius: 50%;
      border: 1px solid #c4c4c4;
    }
  }
}

.avatar {
  display: flex;
  cursor: pointer;
  line-height: 20px;
  span {
    width: 39px;
    height: 39px;
    margin-right: 8px;
    font-size: 30px;
    background-color: $orange;
  }
}

.dropdown {
  padding: 10px 20px;
  :global {
    .ant-dropdown-menu-item {
      border-bottom: 1px solid $line;
      padding: 10px 0;
      :local {
        .icons {
          margin-right: 10px;
        }
      }
    }
    .ant-dropdown-menu-item:last-child {
      border-bottom: 0px;
      color: $red;
    }
  }
}

.buttonHeader {
  display: flex;
  margin-right: 20px;
  .timekeeping {
    margin-right: 10px;
    button {
      background-color: $orange3;
      color: $white;
      border: none;
      padding: 0 20px;
      &:hover {
        background-color: $orange;
      }
    }
  }
  .otregister {
    button {
      background-color: $green;
      padding: 0 20px;
      color: $white;
      border: transparent;
      &:hover {
        background-color: $green1;
      }
    }
  }
}

.freetrial {
  display: flex;
  margin-right: 20px;
  padding: 3px;
  height: 35px;
  background-color: $yellow;
  border-radius: 999px;
  color: $text;
  font-size: 13px;
  cursor: pointer;
  .currency {
    width: 29px;
    height: 29px;
    background-color: $blue2;
    border-radius: 999px;
    text-align: center;
    margin-right: 5px;
    img {
      padding-top: 6px;
    }
  }
  .freeText {
    padding: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    p {
      margin: 0;
      padding: 0 16px 0 0;
      line-height: 3px;
    }
    b {
      position: relative;
      top: -15%;
    }
  }
}

.header {
  :global {
    .ant-form {
      display: flex;
      flex-direction: column;
      height: 600px;
    }
  }
}
.responsiveButton {
  display: flex;
  justify-content: space-between;
  button {
    width: 100%;
  }
  .btnLogin {
    background: white;
    border: 1px solid $blue2;
    color: $blue2;
    border-radius: 3px;
  }
}

.notificationButton {
  margin-right: 15px;
  position: relative;
  .notificationBadge {
    position: absolute;
    top: 0px;
    left: 15px;
  }
}
.buttonCreateConstruction {
  border-color: #c4c4c4;
}
