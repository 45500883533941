.spinContainer {
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
  z-index: 10;
  background-color: rgba($color: white, $alpha: 0.45);
}

.wrapper {
  position: relative;
}
