@import 'styles/variables.scss';

.datePicker {
  width: 132px;
  padding-left: 0;

  :global {
    input {
      color: $blue2;
    }

    input::placeholder {
      color: $blue2;
    }
  }
}

.workerSelectList {
  :global {
    .ant-select-selection-item {
      display: none;
    }
  }
}

.workerList {
  margin-top: -16px;
  margin-bottom: 20px;
  position: relative;

  .workerItem {
    display: flex;
    background-color: #dbeeff;
    border-radius: 3px;
    display: flex;
    padding: 10px;
    align-items: center;
    min-height: 100%;

    :global {
      .ant-form-item {
        margin-bottom: 0;
      }
    }

    &.isBlock {
      background-color: #8e8e8e;
    }

    .info {
      flex: 1;
      margin-left: 10px;
    }
    .remove {
      cursor: pointer;
      position: absolute;
      z-index: 2;
      top: 5px;
      right: 5px;
    }
    .workerName {
      font-size: 13px;
      font-weight: bold;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 60px;
    }

    .vendorName {
      color: $text2;
      font-size: 13px;
      font-weight: bold;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 20px;
    }

    .formPrice {
      width: 100px;

      :global {
        .ant-form-item-label {
          padding-bottom: 0;
        }
      }
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.accordionShift {
  background-color: #dbeeff;
  border-top: none;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  :global {
    .ant-collapse-header {
      font-weight: bold;
      color: $blue2;
    }
  }
}

.colorRed {
  :global {
    .ant-form-item-required {
      color: red;
    }
  }
}

.formAddCertificateCol {
  margin-left: -12px;
  margin-top: -20px;
}

.modal {
  :global {
    .ant-modal-content,
    .ant-modal-header {
      background-color: #f8f8f8;
    }

    .ant-modal-header {
      padding: 16px 24px;
    }

    .ant-modal-body {
      padding: 10px 24px 16px;
    }
  }

  .colWrap {
    background: white;
    padding: 10px;
  }
}

.btnAddOtherShift {
  width: fit-content;
  margin: auto;
  cursor: pointer;
}

.wrapperMaxWorker {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 20px !important;
}
