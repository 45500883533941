.container {
  width: 100%;
  position: relative;
  // padding: 0 12px;

  :global {
    .ant-form-item-label {
      padding: 0;
    }
    .ant-form-item {
      margin-bottom: 10px;
    }
    .ant-form-item-label > label {
      height: auto;
    }
  }

  .row {
    .colWithIcon {
      // gap: 25px;
      gap: 5px;
      justify-content: space-between;
      display: flex;
      align-items: flex-start;
    }
  }
}

.wrapList {
  width: 100%;
  position: relative;
  margin-top: 4px;

  .titleAdd {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    // margin-top: 24px;
    margin-left: 10px;

    &.hide {
      display: none;
      width: 0;
      height: 0;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

.icon {
  cursor: pointer;
  margin-top: 24px;

  &.iconAdd {
    margin-left: 4px;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.iconAddHide {
  display: none;
}

.iconWrap {
  margin-top: 5px;
}
