@import '../../styles/variables.scss';
.baseselect {
  :global {
    .ant-select-selector {
      background-color: #f0f0f0 !important;
      border-radius: 4px !important;
    }
    .ant-select-arrow {
      top: 50%;
      margin-top: -6px;
    }
    // .ant-select-selection-item {
    //   background-color: $blue3;
    //   color: $blue1;
    // }
    .ant-select-selection-item-remove {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.multiple {
  :global {
    .ant-select-selection-item {
      height: 100%;
      background-color: $line;
      .ant-select-selection-item-content {
        padding: 5px 0;
      }
    }
  }
}

.loadingWrap {
  pointer-events: none;

  :global {
    .ant-select-item-option-content {
      display: flex;
      justify-content: center;
    }
  }
}

.variantsMultiple {
  :global {
    .ant-select-selection-overflow {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow: hidden;
      height: 32px;
    }

    .ant-select-selection-item {
      border: 1px solid #d9d9d9;
      background-color: white;
      border-radius: 4px;
      height: 30px;
    }
  }
}
