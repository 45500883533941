.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
