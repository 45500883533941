@import 'styles/variables.scss';

.boxAvatarEdit {
  position: relative;
  width: 130px;
  height: 130px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  :global {
    .ant-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .ant-image-mask {
      border-radius: 50%;
    }
  }
}

.square {
  position: relative;
  border: 1px solid $line;
  width: 150px;
  height: 150px;
  display: block;
  margin-right: auto;
  margin-bottom: 20px;

  .avatar {
    border-radius: unset;
  }
  .iconUpdoadImage {
    right: -15px;
    bottom: -15px;
  }
  .spin {
    border-radius: unset;
    padding-top: 60%;
  }
  .onlyIcon {
    width: 150px;
    height: 150px;
    border-radius: unset;
  }
  :global {
    .ant-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .ant-upload-list {
      display: none;
    }
  }
}

.avatar {
  display: flex;
  line-height: 20px;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
  img {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    border-radius: 40px;
  }
}

.iconUpdoadImage {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.onlyIcon {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  background-color: $background;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 36px;
    transform: translate(-50%, -50%);
  }
}

.rectangle {
  width: 300px;
  height: 200px;
  margin: auto;
  position: relative;
  background-color: $background;
  border: 1px solid $line;
  :global {
    .ant-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .iconUpdoadImage {
    right: -15px;
    bottom: -15px;
  }
  .onlyIcon {
    width: 300px;
    height: 200px;
    display: block;
  }
  img {
    border-radius: 0%;
  }
  .spin {
    border-radius: 0%;
    height: 200px;
    padding: 30%;
  }
}

.notAllow {
  cursor: not-allowed;
}

.spin {
  padding: 43%;
  text-align: center;
  background: #e6f7ff;
  opacity: 0.5;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.deleteWrap {
  position: absolute;
  z-index: 10;
  right: -15px;
  top: -15px;
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: grid;
  place-items: center;

  &.deleteWrapCircle {
    right: 0;
    top: 0;
  }
}
