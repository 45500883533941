.modalPlans {
  :global {
    .ant-modal-body {
      padding: 60px 24px 90px;
      margin-right: 2px;
    }

    .ant-modal-content {
      margin-right: 5px;
    }

    .ant-modal-footer {
      margin-top: 20px;
    }
  }

  .logoWrapper {
    display: flex;
    justify-content: center;

    .logo {
      width: 90px;
    }
  }
}

.wrapper {
  padding: 0 30px 0;
  position: relative;
  margin-top: 52px;
  min-height: 300px;

  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(180deg, #1b6cb1 0%, #439ce9 100%);
    width: 100%;
    height: calc(48% + 20px);
    border-radius: 16px 16px 0 0;
    left: 0;
    top: -20px;
  }

  .title {
    color: white;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
  }

  .listPlan {
    .dot {
      width: 10px;
      height: 10px;
      background-color: #d9d9d9;
      border-radius: 50%;
    }

    .btnSlide {
      width: 32px !important;
      height: 32px !important;
    }

    :global {
      .slick-initialized .slick-slide {
        padding: 10px;
      }

      .slick-next {
        right: -35px;
      }

      .slick-prev {
        left: -35px;
      }

      .slick-dots {
        bottom: -35px;

        .slick-active div {
          background-color: #1890ff;
        }
      }
    }
  }
}
