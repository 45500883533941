@import 'styles/variables.scss';

.workerItem {
  display: flex;
  background-color: #dbeeff;
  border-radius: 3px;
  display: flex;
  padding: 10px;
  // align-items: center;
  min-height: 100%;

  flex-direction: column;
  justify-content: end;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .info {
    flex: 1;
    // margin-left: 10px;
  }
  .remove {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 5px;
    right: 5px;
  }
  .workerName {
    font-size: 13px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 60px;
  }

  .vendorName {
    color: $text2;
    font-size: 13px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 20px;
  }

  .formPrice {
    width: 90px;

    :global {
      .ant-form-item-label {
        padding-bottom: 0;
      }
    }
  }

  .addNewRow {
    img {
      width: 20px;
    }
  }
}
