@import 'styles/variables.scss';

.container {
  :global {
    .ant-select-selector {
      background-color: $background !important;
      border-radius: 4px !important;
    }
  }
}
.selectAll {
  margin-left: 10px;
  label {
    margin-right: 5px;
  }
}

.option {
  display: flex;
  align-items: center;
  .info {
    margin-left: 10px;
  }
}
.textSelectedWorker {
  color: $blue2;
  margin-top: 10px;
  a {
    margin-left: 20px;
    text-decoration: underline;
  }
}

.selectedItem {
  display: flex;
  height: 100%;
  .wrapper {
    flex: 1;
    display: flex;
    background-color: #dbeeff;
    border-radius: 3px;
    margin-top: 5px;
    padding: 5px 10px;
    align-items: center;
    .info {
      flex: 1;
      margin-left: 10px;
      .name {
        font-size: 13px;
        font-weight: bold;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: 60px;
      }
      .manager {
        font-size: 13px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: 20px;
        color: $text2;
      }
    }
  }
}

.disabled {
  cursor: not-allowed;
}
