@import '../../styles/variables.scss';

.header {
  :global {
    .ant-input[disabled] {
      color: $blue2;
    }
    .ant-form-item-control-input-content {
      text-align: end;
    }
  }
}

.formContent {
  margin: 35px 0 !important;
  text-align: center;
}

.btnWhite {
  width: 100%;
  margin-top: 15px;
}

.btnSubmit {
  width: 100%;
  margin-top: 15px;
}
