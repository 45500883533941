@import 'styles/variables.scss';
.modalEmailVendor {
  max-width: 690px;
  width: 100% !important;
}

.pModal {
  :global {
    .ant-modal-body {
      padding: 50px 100px;
    }
  }
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 30px;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  button {
    background-color: $blue2;
    color: white;
    height: 45px;
    min-width: 237px;
    border: none;
    border-radius: 3px;
    margin-top: 20px;
    font-weight: bold;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .btnWhite {
    background: white;
    border: 1px solid $blue2;
    color: $blue2;
  }

  &.footerAddVendor {
    justify-content: flex-end !important;
  }
}
