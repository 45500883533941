@import 'styles/variables.scss';

.loginWrapper {
  display: flex;
  flex: 1 1;
}

.loginContainer {
  margin: 0 auto;
  padding: 0px 30px;
  display: flex;
  justify-content: center;
  min-width: 500px;
  height: 100vh;
  overflow: auto;
  button {
    margin-top: 20px;
    width: 100%;
    height: 40px;
  }
  input {
    padding: 10px 15px;
    background-color: $background !important;
  }
  :global {
    .ant-input-affix-wrapper {
      background-color: $background;
      padding: 10px 15px;
      input {
        background-color: $background;
      }
    }
    .ant-form {
      display: flex;
      flex-direction: column;
    }
  }
}

.companyName {
  display: inline-grid;
  .ellipsis {
    width: 230px;
    margin-bottom: 0;
  }
}
.login {
  padding: 20px 0px;
  margin: 0;
  font-weight: bold;
  font-size: 30px;
  line-height: 16px;
  color: $text !important;
  background-color: transparent !important;
  width: 100%;
  text-align: center;
}

.buttonSelect {
  width: 100%;
  .logoButton {
    width: 100%;
    margin: 15px 0px;
    text-align: left;
    .logoImage {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
      margin: 0px 15px 0px 5px;
    }
  }
  .logoActive {
    border: 1px solid #2b88d8;
  }
}

.buttonLogin {
  width: 100%;
  margin-left: 0px !important;
  margin-bottom: 20px;
}

.or {
  width: 100%;
  text-align: center;
  display: block;
}
.termLogin {
  text-decoration: underline !important;
  color: $text !important;
  text-align: center;
  display: block;
}

.role {
  width: 100%;
  display: block;
  margin-top: -12px;
  :global {
    .ant-radio-wrapper {
      display: block;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid $line;
      border-radius: 4px;
    }
    .ant-radio-wrapper-checked {
      border-color: $blue2;
    }
  }
}

.listCompany {
  flex: 1;
  overflow: auto;
  margin-top: 20px;
  min-width: 344px;
  min-height: 250px;
}

.or {
  width: 100%;
  text-align: center;
  display: block;
}

.align {
  display: block;
  text-align: center;
}

.profileCompany {
  padding-top: 10px;
  text-align: center;
  :global {
    h4.ant-typography {
      padding-top: 15px;
    }
  }
}

.spin {
  width: 100%;
  text-align: center;
  padding: 50px 0;
}

@media (max-width: 575px) {
  .loginContainer {
    min-width: 100%;
    padding: 0px 20px;
  }
  // .loginForm {
  //   display: none;
  // }
}

@media (min-width: 576px) and (max-width: 768px) {
  .modalEmailVendor {
    background: white;
    padding: 0;
    margin: 0;
    height: 100%;
  }
  .pModal {
    :global {
      .ant-modal-content {
        box-shadow: none;
        -webkit-box-shadow: none;
      }
      .ant-modal-body {
        padding: 50px 20px;
        max-height: 100%;
      }
    }
  }
  // .loginForm {
  //   display: none;
  // }
}

@media (max-width: 767px) and (max-width: 991.98px) {
  .responsiveButton {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    .or {
      display: none;
    }
    button {
      width: 155px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .responsiveButton {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    .or {
      display: none;
    }
    button {
      width: 155px;
    }
  }
}

@media (min-width: 1198.98px) and (max-width: 1366px) {
  .responsiveButton {
    display: flex !important;
    flex-direction: row-reverse;
    justify-content: space-between;
    .or {
      display: none !important;
    }
    button {
      width: 155px;
    }
  }
}

.pModal {
  :global {
    .ant-modal-body {
      padding: 50px;
    }
  }
}

// @media (min-width: 1367px) {
//   .responsiveButton {
//     display: flex;
//     .or {
//       display: none;
//     }
//   }
//   .loginContainer {
//     button {
//       margin-top: 15px;
//       width: 150px;
//       height: 40px;
//     }
//   }
// }
