@import '../../styles/variables.scss';

.icons {
  background-color: #f0f0f0;
  border: none;
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  color: #757575;
  margin-left: 15px;
  outline: none;

  &:hover,
  &:focus {
    background-color: $line;
    color: $text2;
  }

  img {
    margin-right: 8px;
    margin-top: -3px;
    height: 18px;
  }
}

// styles for default button

:global {
  :local(.customButton) {
    &.ant-btn-default {
      font-weight: 700;
      color: #8e8e8e;
    }
  }
}

:global {
  :local(.customButton.active) {
    &.ant-btn-default {
      background-color: #dbeeff;
    }
  }
}

:global {
  :local(.customButton.reject) {
    &.ant-btn {
      background: white;
      border: 1px solid $red !important;
      color: $red !important;
    }
  }
}

:global {
  :local(.customButton.accept) {
    &.ant-btn {
      background: white;
      border: 1px solid $green !important;
      color: $green !important;
    }
  }
}

:global {
  :local(.customButton.danger) {
    &.ant-btn {
      background-color: $red;
      border-color: $red;
    }
  }
}
