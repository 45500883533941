@import '../../styles/variables.scss';
.input {
  height: 32px;
  width: 100%;
  background-color: $line !important;
  border-radius: 4px;
  position: relative;

  :global {
    .ant-input {
      background-color: $line !important;
    }
  }
}

.input::-webkit-scrollbar-track {
  border-radius: 10px;
}

.input::-webkit-scrollbar {
  width: 6px;
}

.input::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $text2;
}
