.wrapper {
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  overflow: hidden;
  height: 430px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    height: 70px;
    padding: 16px;
    display: grid;
    place-items: center;
    font-size: 16px;
    color: #2b88d8;
    font-weight: 700;
    flex-shrink: 0;
  }

  .priceWrapper {
    text-align: center;
    background-color: #f8f8f8;
    height: 90px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .value {
      font-weight: 500;
      font-size: 27px;
      color: #0e518c;
    }

    .unit {
      color: #2b88d8;
      font-weight: 700;
      font-size: 15px;
    }
  }

  .body {
    padding: 18px 10px;
    // flex: 1;

    .totalUser {
      font-size: 14px;
      font-weight: 700;
      padding: 10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }

    .desc {
      text-align: center;
      font-size: 14px;
      color: #2d2d2d;
      white-space: 'pre-wrap';
    }
  }

  .footer {
    flex: 1;
    display: flex;
    align-items: end;
    justify-content: center;
    padding-bottom: 24px;

    .btnSelect {
      width: 200px;
    }
  }
}
