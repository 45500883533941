@import 'styles/variables.scss';

.container {
  :global {
    .ant-select-selector {
      background-color: $background !important;
      border-radius: 4px !important;
    }
  }
}
.option {
  display: flex;
  align-items: center;
  .info {
    margin-left: 10px;
  }
}

.selectedItem {
  background-color: #ffe8e8;
  display: flex;
  margin-top: 5px;
  padding: 5px 10px;
  border-radius: 3px;
  align-items: center;
  min-height: 60px;

  .info {
    flex: 1;
    margin-left: 10px;
    font-size: 13px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 60px;
  }
  .remove {
    cursor: pointer;
  }
}
.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
