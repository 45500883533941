@import '../../../src/styles/variables.scss';
.payroll {
  background: $background;
}

.paperWrapper {
  // padding: 0 20px 0 25px;
  padding-top: 15px;
  :global {
    .ant-tabs-nav {
      &::before {
        border: none;
      }
    }
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-tab {
      padding: 0;
      margin-right: 16px;
      &:first-child {
        .ant-tabs-tab-btn {
          padding-right: 16px;
          border-right: 2px solid $text2;
        }
      }
    }
    .ant-tabs-tab-btn {
      color: #8e8e8e !important;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $blue2 !important;
    }
    .ant-tabs-tab-btn,
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-size: 20px;
      font-weight: 700 !important;
      line-height: 1.5;
    }
  }
}

.payroll {
  :global {
    .ant-table {
      display: block;
      background: $background;
    }
    .ant-table-thead > tr > th {
      color: #2d2d2d;
      white-space: nowrap;
    }
    .ant-table-tbody > tr > td {
      max-width: 250px;
    }
    .ant-table-content {
      overflow-y: hidden;
      overflow-x: auto;
    }
  }
}

.tbApproved {
  :global {
    // .ant-table table {
    //   min-width: auto !important;
    //   width: auto;
    // }
    .ant-table-thead > tr > th:nth-child(2),
    .ant-table-thead > tr > th:nth-child(3),
    .ant-table-thead > tr > th:nth-child(5),
    .ant-table-thead > tr > th:nth-child(4),
    .ant-table-thead > tr > th:nth-child(8) {
      min-width: 140px;
    }
    .ant-table-thead > tr > th:nth-child(1),
    .ant-table-thead > tr > th:nth-child(6),
    .ant-table-thead > tr > th:nth-child(7) {
      min-width: 200px;
    }
  }
}

.tbRequest {
  :global {
    // .ant-table table {
    //   min-width: auto !important;
    //   width: auto;
    // }
    .ant-table-thead > tr > th:nth-child(1),
    .ant-table-thead > tr > th:nth-child(4),
    .ant-table-thead > tr > th:nth-child(5) {
      min-width: 200px;
    }
  }
}

.boxAvatarEdit {
  position: relative;
  width: 130px;
  height: 130px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  :global {
    .ant-upload-list-text-container {
      display: none;
    }
  }
}

:global {
  .ant-picker-suffix {
    font-size: 18px;
    color: #757575;
    font-weight: bold;
  }
}

.staffList {
  width: 193px;
  border-right: 1px solid #f0f0f0;
  overflow-y: auto;
}

.salaryLeft {
  width: calc(100% - 193px);
  overflow-y: auto;
}

.content0 {
  margin: -20px;
  display: flex;
}

.noteDot {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 14px;
}

.noteColor {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 13px;
}

.salaryTop {
  min-height: 58px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  padding: 0 10px;
}

.salaryTopFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.salaryTop1 {
  display: block;
}

.salaryTop2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.salaryTop3 {
  :global {
    .btnGrey {
      margin-bottom: 0 !important;
    }
  }
}

.salaryLink {
  color: #2d2d2d;
  &.active {
    color: $blue2;
    font-weight: bold;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      bottom: -20px;
      left: 0;
      right: 0;
      height: 2px;
      background: $blue2;
    }
  }
}

.staffBody {
  .menuItem {
    display: block;
    padding: 10px 10px;
    position: relative;
    cursor: pointer;
    &.active {
      background: $background;
    }
    .badge {
      position: absolute;
      top: 8px;
      right: 4px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      background-origin: padding-box;
      color: $white;
      min-width: 14px;
      height: 14px;
      background-color: #eb5757;
      border-radius: 50%;
    }
    &:last-child img {
      border-radius: 50%;
      border: 1px solid #c4c4c4;
    }
  }
}

.avatar {
  display: flex;
  line-height: 20px;
  img {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    border-radius: 40px;
  }
}

.staffInfo {
  width: calc(100% - 48px);
}

.boxSearch {
  border-bottom: 1px solid $line;
  :global {
    .ant-input-search .ant-input-group .ant-input {
      padding: 19px 10px;
      border: none;
      color: #000000;
      font-weight: bold;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &::placeholder {
        color: #000000;
      }
    }
    .ant-input-search > .ant-input-group .ant-input-search-button {
      height: 60px;
      border: none;
      color: $text !important;
    }
  }
}

.btnOperating {
  border: none;
  padding: 3px 5px;
  background: transparent;
}

.flexCenter {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 30px;
}

.cell {
  margin-bottom: 15px;
  > label {
    display: inline-block;
    margin-bottom: 15px;
  }
  > * {
    color: $text;
    font-size: 13px;
  }
}

.title {
  color: $text;
  font-size: 13px;
  margin-bottom: 10px;
}

.maps iframe {
  border: none;
  border-radius: 3px;
}

.footer {
  display: flex;
  justify-content: center;
  button {
    background-color: $blue2;
    color: white;
    height: 45px;
    min-width: 237px;
    border: none;
    border-radius: 3px;
    margin-top: 20px;
    font-weight: bold;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  .btnWhite {
    background: white;
    border: 1px solid $blue2;
    color: $blue2;
  }
  &.footerAddVendor {
    justify-content: flex-end !important;
  }
}

.ml150 {
  margin-left: 150px;
}

.borderTopVendor {
  border-bottom: 1px solid $line;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.searchVendor {
  border: 1px solid $line;
  border-radius: 3px;
  display: flex;
  :global {
    button {
      background: none;
      border: none;
      padding: 4px 10px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    input {
      border: none;
      padding: 4px 10px 4px 0;
      min-width: 240px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.modalEmailVendor {
  max-width: 690px;
  width: 100% !important;
}

.modalAddVendor {
  max-width: 1162px;
  width: 100% !important;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  padding: 20px;
}

.pModal {
  :global {
    .ant-modal-body {
      padding: 50px 100px;
    }
  }
}

.inputDate {
  width: 100%;
}

.boxDetailVendor {
  display: flex;
}

.DetailVendorLeft {
  width: 374px;
  height: calc(100vh - 65px);
  overflow-y: auto;
  border-right: 1px solid $line;
  padding: 20px;
}

.DetailVendorRight {
  width: calc(100% - 374px);
  height: calc(100vh - 65px);
  overflow-y: auto;
  :global {
    .ant-tabs > .ant-tabs-nav {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.dotActive {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background: $green;
  margin-right: 5px;
}

.dotUnActive {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background: $red;
  margin-right: 5px;
}

.infoVendor {
  margin-bottom: 10px;
}

.changePass {
  font-weight: bold;
  color: $blue2;
  font-size: 13px;
}

.iconLeft {
  filter: grayscale(1);
  transform: rotate(-90deg);
}

.linkTabWork {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: $background;
  border: 1px solid $line;
  padding: 9px 10px;
}

.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.boxAvatarEdit {
  position: relative;
  width: 130px;
}

.avatar {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  object-fit: cover;
}

.iconUpdoadImage {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.filterAccount {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.icDownload {
  margin-left: 5px;
  cursor: pointer;
}

.icDownloadDisable {
  pointer-events: none;
  margin-left: 5px;
}

.attachFile {
  display: flex;
  justify-content: flex-start;
}

.btn {
  width: 60% !important;
}
.btnAccept {
  text-align: center;
}
.btnPrimary {
  background-color: $blue2;
  color: white;
  height: 45px;
  border: none;
  border-radius: 3px;
  margin-top: 20px;
  font-weight: bold;
  width: 100%;
  &:focus {
    box-shadow: none;
    outline: none;
  }
}
.btnWhite {
  background: white;
  border: 1px solid $blue2;
  color: $blue2;
  height: 45px;
  border-radius: 3px;
  margin-top: 20px;
  width: 100%;
}

@media (max-width: 575px) {
  .modalStaff {
    background: white;
    padding: 0;
    margin: 0;
    height: 100%;
  }
  .pModal {
    :global {
      .ant-modal-content {
        box-shadow: none;
        -webkit-box-shadow: none;
      }
      .ant-modal-body {
        padding: 50px 20px;
        max-height: 100%;
        button {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .modalStaff {
    background: white;
    padding: 0;
    margin: 0;
    height: 100%;
  }
  .pModal {
    :global {
      .ant-modal-content {
        box-shadow: none;
        -webkit-box-shadow: none;
      }
      .ant-modal-body {
        padding: 50px 20px;
        max-height: 100%;
      }
      // .ant-form {
      //   display: none;
      // }
    }
  }
}
