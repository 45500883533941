@import '../../../src/styles/variables.scss';
.payroll {
  background: $background;
}

.payroll {
  :global {
    .ant-table {
      display: block;
      background: $background;
    }
    .ant-table-thead > tr > th {
      color: #2d2d2d;
      white-space: nowrap;
    }
    .ant-table-tbody > tr > td {
      max-width: 250px;
    }
    .ant-table-content {
      overflow-y: hidden;
      overflow-x: auto;
    }
  }
}

.btnGrey {
  background: #f0f0f0;
  border-radius: 3px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0 10px;
  font-size: 13px;
  color: #757575;
  cursor: pointer;
  :global {
    .ant-picker-input input::placeholder {
      color: #757575 !important;
    }
  }
}

:global {
  .ant-picker-suffix {
    font-size: 18px;
    color: #757575;
    font-weight: bold;
  }
}

.content0 {
  margin: -20px;
  display: flex;
}

.noteDot {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 14px;
}

.noteColor {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 13px;
}

.salaryTop {
  min-height: 58px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  padding: 0 10px;
}

.salaryTopFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.salaryTop1 {
  display: block;
}

.salaryTop2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.salaryTop3 {
  :global {
    .btnGrey {
      margin-bottom: 0 !important;
    }
  }
}

.salaryLink {
  color: #2d2d2d;
  &.active {
    color: $blue2;
    font-weight: bold;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      bottom: -20px;
      left: 0;
      right: 0;
      height: 2px;
      background: $blue2;
    }
  }
}

.optionPaper {
  width: 150px;
}
.staffBody {
  .menuItem {
    display: block;
    padding: 10px 10px;
    position: relative;
    cursor: pointer;
    &.active {
      background: $background;
    }
    .badge {
      position: absolute;
      top: 8px;
      right: 4px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      background-origin: padding-box;
      color: $white;
      min-width: 14px;
      height: 14px;
      background-color: #eb5757;
      border-radius: 50%;
    }
    &:last-child img {
      border-radius: 50%;
      border: 1px solid #c4c4c4;
    }
  }
}

.avatar {
  display: flex;
  line-height: 20px;
  img {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    border-radius: 40px;
  }
}

.staffInfo {
  width: calc(100% - 48px);
}

.boxSearch {
  border-bottom: 1px solid $line;
  :global {
    .ant-input-search .ant-input-group .ant-input {
      padding: 19px 10px;
      border: none;
      color: #000000;
      font-weight: bold;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &::placeholder {
        color: #000000;
      }
    }
    .ant-input-search > .ant-input-group .ant-input-search-button {
      height: 60px;
      border: none;
      color: $text !important;
    }
  }
}

.btnOperating {
  border: none;
  padding: 3px 5px;
  background: transparent;
}

.flexCenter {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.hour {
  font-size: 11px;
  color: $text;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 30px;
}

.cell {
  margin-bottom: 15px;
  > label {
    display: inline-block;
    margin-bottom: 15px;
  }
  > * {
    color: $text;
    font-size: 13px;
  }
}

.title {
  color: $text;
  font-size: 13px;
  margin-bottom: 10px;
}

.maps iframe {
  border: none;
  border-radius: 3px;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  button {
    background-color: $blue2;
    color: white;
    height: 45px;
    min-width: 237px;
    border: none;
    border-radius: 3px;
    margin-top: 20px;
    font-weight: bold;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  .btnWhite {
    background: white;
    border: 1px solid $blue2;
    color: $blue2;
  }
  &.footerAddVendor {
    justify-content: flex-end !important;
  }

  &.footerInviteCustomer {
    justify-content: center;
  }
}

.ml150 {
  margin-left: 150px;
}

.borderTopVendor {
  border-bottom: 1px solid $line;
  padding-bottom: 10px;
  margin-bottom: 10px;
  button {
    font-weight: 400;
  }
}

.searchVendor {
  border: 1px solid $line;
  border-radius: 3px;
  display: flex;
  :global {
    button {
      background: none;
      border: none;
      padding: 4px 10px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    input {
      border: none;
      padding: 4px 10px 4px 0;
      min-width: 240px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.modalEmailVendor {
  max-width: 690px;
  width: 100% !important;
}

.modalAddVendor {
  max-width: 1162px;
  width: 100% !important;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  padding: 20px;
}

.pModal {
  :global {
    .ant-modal-body {
      padding: 50px 100px;
    }
  }
}

.inputDate {
  width: 100%;
}

.boxDetailVendor {
  display: flex;
}

.DetailVendorLeft {
  width: 374px;
  height: calc(100vh - 65px);
  overflow-y: auto;
  border-right: 1px solid $line;
  padding: 20px;
}

.DetailVendorRight {
  width: calc(100% - 374px);
  height: calc(100vh - 65px);
  overflow-y: auto;
  :global {
    .ant-tabs > .ant-tabs-nav {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.dotActive {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background: $green;
  margin-right: 5px;
}

.dotUnActive {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background: $red;
  margin-right: 5px;
}

.infoVendor {
  margin-bottom: 10px;
}

.changePass {
  font-weight: bold;
  color: $blue2;
  font-size: 13px;
}

.iconLeft {
  filter: grayscale(1);
  transform: rotate(-90deg);
}

.linkTabWork {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: $background;
  border: 1px solid $line;
  padding: 9px 10px;
}

.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.boxAvatarEdit {
  position: relative;
  width: 130px;
}

.avatar {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  object-fit: cover;
}

.iconUpdoadImage {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.tbCustomer {
  margin-top: 20px;

  :global {
    .ant-table-thead > tr > th {
      width: 250px;
    }
  }
}

.btnClose {
  margin: auto;
  width: 200px;
}

.tbPaper {
  :global {
    .ant-table-thead > tr > th:nth-child(3),
    .ant-table-thead > tr > th:nth-child(4),
    .ant-table-thead > tr > th:nth-child(5),
    .ant-table-thead > tr > th:nth-child(6) {
      width: 200px;
    }
  }
}

.paperWrapper {
  margin-left: 20px;
  margin-right: 20px;
  :global {
    .ant-tabs-nav {
      &::before {
        border: none;
      }
    }
    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-tab {
      padding: 0;
      margin-right: 16px;
      margin-top: 30px;
      &:first-child {
        .ant-tabs-tab-btn {
          padding-right: 16px;
          border-right: 2px solid $text2;
        }
      }
    }
    .ant-tabs-tab-btn,
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-size: 20px;
      font-weight: 700 !important;
      line-height: 1;
    }
  }
  .document {
    padding: 0 20px 0 25px;
  }
  .inputGroup {
    width: 400px;
    display: flex;
    justify-content: space-between;
    .inputGroupSelect {
      width: 230px;
    }
  }
}

.form {
  padding: 15px;
  border: 1px solid $lightGrey;
}

.reactQuill {
  height: 350px;
  .quill {
    height: 300px;
  }
}

.modalAddDocument {
  max-width: 1162px;
  width: 100% !important;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  padding: 20px;
}

.modalAddBill {
  max-width: 730px;
  width: 100% !important;
  margin-top: 0px !important;
  margin-bottom: 50px !important;
  padding: 20px;
  :global {
    .ant-upload-list {
      display: none;
    }
  }
}

.closeButton {
  display: flex;
  flex-direction: row-reverse;
  margin-top: -40px;
  margin-right: -90px;
}

.datePicker {
  width: 100%;
  background-color: $line !important;
  border-radius: 4px;
  :global {
    .ant-picker-suffix {
      font-size: 16px;
      color: $inactive;
    }
    .ant-picker-clear {
      font-size: 16px;
      background-color: $line;
    }
  }
}

.footerForm {
  border: 1px solid lightGrey;
  background-color: $blue2;
  color: $white;
  padding: 10px;
  margin: -9px -1px 0px -1px;
  li {
    margin: 5px 0;
  }
}

.footer {
  display: flex;
  justify-content: space-around;
  button {
    background-color: $blue2;
    color: white;
    height: 45px;
    min-width: 237px;
    border: none;
    border-radius: 3px;
    margin-top: 20px;
    font-weight: bold;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .btnWhite {
    background: white;
    border: 1px solid $blue2;
    color: $blue2;
  }
}

//revenue_cost
.revenue_cost {
  padding: 0px 20px 0 0px;
  .statistics {
    display: flex;
    .box_statistics {
      background-color: $line;
      text-align: center;
      margin-right: 20px;
      width: 100%;
      border-radius: 4px;
      padding: 10px 0;
      &:last-child {
        margin-right: 0;
      }
      h4,
      h5 {
        margin: 0;
      }
      h4 {
        padding-top: 20px;
      }
    }
  }
  .revenue_cost_table {
    margin-top: 10px;
    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .inputGroup {
        width: fit-content;
        & > * {
          margin-right: 10px;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
}

.tbPaper {
  :global {
    .ant-table-thead > tr > th:nth-child(1) {
      width: 100px;
    }
    .ant-table-thead > tr > th:nth-child(2),
    .ant-table-thead > tr > th:nth-child(3),
    .ant-table-thead > tr > th:nth-child(4),
    .ant-table-thead > tr > th:nth-child(5) {
      width: 200px;
    }
  }
}

.name {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 20px;
}

.footerForm {
  border: 1px solid lightGrey;
  background-color: $blue2;
  color: $white;
  padding: 10px;
  margin: -9px -1px 0px -1px;
  li {
    margin: 5px 0;
  }
}

.footer {
  display: flex;
  justify-content: center;
  button {
    background-color: $blue2;
    color: white;
    height: 45px;
    min-width: 237px;
    border: none;
    border-radius: 3px;
    margin-top: 20px;
    font-weight: bold;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  .btnWhite {
    background: white;
    border: 1px solid $blue2;
    color: $blue2;
  }
  &.footerAddVendor {
    justify-content: center !important;
  }
}

.modalAddVendor {
  max-width: 1162px;
  width: 100% !important;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  padding: 20px;
  :global {
    .ant-upload-list {
      display: none;
    }
  }
}

.selectCustom {
  :global {
    .ant-select-selector {
      background-color: $line !important;
      border: none !important;
      border-radius: 3px;
    }
  }
}

.addField {
  position: absolute;
  top: 135px;
  right: 13px;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}

.addCertificate {
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}

.wrapContent {
  position: relative;
  margin-bottom: 30px;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.wrapperUnitPrice {
  width: 100%;
  // margin-top: 10px;

  .unitPrice {
    margin-left: 0 !important;
    width: 100%;
  }
  .iconWrap {
    padding-left: 0 !important;
    padding-right: 0 !important;
    top: 32px;
    cursor: pointer;
    text-align: left;

    .icon {
      display: block;

      &.disabled {
        cursor: not-allowed;
      }
    }
  }
}

.wrapper {
  justify-content: space-between !important;
}
.inputNumber {
  border-radius: 3px;
  border: none;
  background-color: $line !important;
}
.selectCheck {
  font-weight: 700;
  color: $text;
  font-size: 14px;
}
.formAddCustomer .formInviteCustomer,
.formEditCustomer {
  font-family: 'Roboto', sans-serif;
}
.btnSubmit {
  width: 500px;
  @media screen and (max-width: 750px) {
    width: 100%;
  }
}
.dataUnitPrice {
  .dataUnitItem {
    &:nth-child(odd) {
      width: 58%;
    }
    &:nth-child(even) {
      width: 42%;
    }
  }
}

.titleSection {
  font-weight: 700;
  line-height: 32px;
  color: #2d2d2d;
  margin-right: 5px;
}
