.container {
  width: 100%;
  position: relative;
  padding: 0 12px;

  .title {
    font-weight: 700;
    margin-bottom: 10px;
  }

  .row {
    align-items: center;

    :global {
      .ant-row.ant-form-item {
        margin-bottom: 20px;
      }
    }

    .colWithIcon {
      display: flex;
      align-items: center;
      gap: 25px;
      justify-content: space-between;
    }
  }
}

.wrapList {
  width: 100%;
  position: relative;
}

.icon {
  cursor: pointer;
  margin-top: 24px;

  &.iconAdd {
    margin-left: 4px;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.iconAddHide {
  display: none;
}
