@import 'styles/variables.scss';

.bannerRight {
  .bannerRightImage {
    margin-bottom: 16px;
    margin: 50px auto 20px;
    width: 100px;
    img {
      width: 100%;
    }
  }

  h2 {
    font-size: 30px;
    line-height: 21px;
    font-weight: normal;
    color: #0e518c;
    text-align: center;
    margin: 0;
    span {
      &:first-child {
        color: $blue1;
      }
      color: $blue2;
    }
  }
}

.banner {
  background-color: $blue2;
  width: 100%;
  height: 100vh;
  color: $white;
  position: relative;
  // background-image: url('../../assets/icons/banner.png');
  // background-repeat: no-repeat;
  // background-position: right 50px top 260px;
  // background-size: 45%;

  .bannerBg {
    position: absolute;
    width: 55%;
    aspect-ratio: 1/1;
    object-fit: contain;
    right: 50px;
  }

  .logo {
    position: absolute;
    right: 5%;
    z-index: 0;
    bottom: 5%;
    top: 24%;
  }
  img {
    width: 100%;
    height: 100%;
  }
  h2 {
    padding: 20px 0 0 50px;
    width: 85%;
    font-size: 50px;
    font-weight: 900;
    line-height: 70px;
    z-index: 10;
    color: $white;
    position: relative;
  }
  p {
    font-size: 15px;
    line-height: 20px;
    padding-left: 47px;
  }
}

@media (max-width: 575px) {
  .banner {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .banner {
    display: none;
  }
}

// @media (min-width: 769px) and (max-width: 991px) {
//   .banner {
//     display: none;
//   }
// }

@media only screen and (max-width: 991.98px) {
  .banner {
    background-size: 40%;
    h2 {
      font-size: 35px;
      line-height: 40px;
    }
  }
  .loginContainer {
    h2 {
      font-size: 25px;
    }
  }
  .bannerRight {
    .bannerRightImage {
      width: 60px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .banner {
    background-size: 50%;
    h2 {
      font-size: 35px;
      line-height: 40px;
    }
  }
  .loginContainer {
    h2 {
      font-size: 30px;
    }
  }
  .bannerRight {
    .bannerRightImage {
      width: 70px;
    }
  }
}

@media (min-width: 1198.98px) and (max-width: 1366px) {
  .banner {
    background-size: 50%;
  }

  .bannerRight {
    .bannerRightImage {
      width: 80px;
    }
  }
}

@media (min-width: 1367px) {
  .banner {
    background-size: 50%;
  }
}
