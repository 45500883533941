@import 'styles/variables.scss';

.vendorItem {
  background-color: #ffe8e8;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  width: 100%;
  color: #757575;

  .info {
    margin-left: 10px;
    flex: 1;
  }

  .name {
    font-size: 13px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 60px;
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .inputPrice {
      width: 90px;
      text-align: right;
    }
  }
}

.staffItem {
  background-color: $blue3;
  display: flex;
  // align-items: center;
  padding: 5px 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  width: 100%;
  color: #757575;

  .info {
    margin-left: 10px;
    flex: 1;
  }

  .linkWorker {
    display: inline-block;
    color: inherit;
    height: fit-content;

    &:hover {
      color: #1890ff;
    }
  }

  .name {
    font-size: 13px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 60px;
    width: fit-content;
  }

  .bottom {
    .inputPrice {
      width: 90px;
      text-align: right;
    }
  }
}

.isBlock,
.disableLink {
  pointer-events: none;
}

.unActive {
  background-color: #8e8e8e;
}
