@import '../../../src/styles/variables.scss';
.payroll {
  background: $background;
}

.payroll {
  :global {
    .ant-table {
      display: block;
      background: $background;
    }
    .ant-table-thead > tr > th {
      color: #2d2d2d;
      white-space: nowrap;
    }
    .ant-table-tbody > tr > td {
      max-width: 250px;
    }
    .ant-table-content {
      overflow-y: hidden;
      overflow-x: auto;
    }
  }
}

.tbVendor {
  :global {
    .ant-table-thead > tr > th {
      width: 12.5%;
      &:last-child {
        width: 200px;
      }
    }
  }
}

.btnGrey {
  background: #f0f0f0;
  border-radius: 3px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0 10px;
  font-size: 13px;
  color: #757575;
  cursor: pointer;
  :global {
    .ant-picker-input input::placeholder {
      color: #757575 !important;
    }
  }
}

:global {
  .ant-picker-suffix {
    font-size: 18px;
    color: #757575;
    font-weight: bold;
  }
}

.staffList {
  width: 193px;
  border-right: 1px solid #f0f0f0;
  height: calc(100vh - 65px);
  overflow-y: auto;
}

.salaryLeft {
  width: calc(100% - 193px);
  height: calc(100vh - 65px);
  overflow-y: auto;
}

.content0 {
  margin: -20px;
  display: flex;
}

.noteDot {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 14px;
}

.noteColor {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 13px;
}

.salaryTop {
  min-height: 58px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  padding: 0 10px;
}

.salaryTopFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.salaryTop1 {
  display: block;
}

.salaryTop2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.salaryTop3 {
  :global {
    .btnGrey {
      margin-bottom: 0 !important;
    }
  }
}

.salaryLink {
  color: #2d2d2d;
  &.active {
    color: $blue2;
    font-weight: bold;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      bottom: -20px;
      left: 0;
      right: 0;
      height: 2px;
      background: $blue2;
    }
  }
}

.staffBody {
  .menuItem {
    display: block;
    padding: 10px 10px;
    position: relative;
    cursor: pointer;
    &.active {
      background: $background;
    }
    .badge {
      position: absolute;
      top: 8px;
      right: 4px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      background-origin: padding-box;
      color: $white;
      min-width: 14px;
      height: 14px;
      background-color: #eb5757;
      border-radius: 50%;
    }
    &:last-child img {
      border-radius: 50%;
      border: 1px solid #c4c4c4;
    }
  }
}

.avatar {
  display: flex;
  line-height: 20px;
  img {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    border-radius: 40px;
  }
}

.staffInfo {
  width: calc(100% - 48px);
}

.boxSearch {
  border-bottom: 1px solid $line;
  :global {
    .ant-input-search .ant-input-group .ant-input {
      padding: 19px 10px;
      border: none;
      color: #000000;
      font-weight: bold;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &::placeholder {
        color: #000000;
      }
    }
    .ant-input-search > .ant-input-group .ant-input-search-button {
      height: 60px;
      border: none;
      color: $text !important;
    }
  }
}

.btnOperating {
  border: none;
  padding: 3px 5px;
  background: transparent;
  &:hover {
    background-color: transparent;
  }
}

.flexCenter {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 20px;
}

.cell {
  margin-bottom: 15px;
  > label {
    display: inline-block;
    margin-bottom: 15px;
  }
  > * {
    color: $text;
    font-size: 13px;
  }
}

.title {
  color: $text;
  font-size: 13px;
  margin-bottom: 10px;
}

.maps iframe {
  border: none;
  border-radius: 3px;
}

.btnPrimary {
  background-color: $blue2;
  color: white;
  height: 45px;
  border: none;
  border-radius: 3px;
  margin-top: 20px;
  font-weight: bold;
  width: 100%;
  &:focus {
    box-shadow: none;
    outline: none;
  }
}
.btnWhite {
  background: white;
  border: 1px solid $blue2;
  color: $blue2;
  width: 100%;
  height: 45px;
  border-radius: 3px;
  margin-top: 20px;
}
.btnAccept {
  margin: auto;
  width: 200px;
}
.ml150 {
  margin-left: 150px;
}

.borderTopVendor {
  border-bottom: 1px solid $line;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.searchVendor {
  border: 1px solid $line;
  border-radius: 3px;
  display: flex;
  :global {
    button {
      background: none;
      border: none;
      padding: 4px 10px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    input {
      border: none;
      padding: 4px 10px 4px 0;
      min-width: 240px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.modalAddVendor {
  max-width: 1162px;
  width: 100%;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  padding: 20px;
  :global {
    .ant-upload-list {
      display: none;
    }
  }
}

.pModal {
  :global {
    .ant-modal-body {
      padding: 50px;
    }
  }
}

.inputDate {
  width: 100%;
}

.boxDetailVendor {
  display: flex;
}

.DetailVendorLeft {
  width: 374px;
  height: calc(100vh - 65px);
  overflow-y: auto;
  border-right: 1px solid $line;
  padding: 20px;
}

.DetailVendorRight {
  width: calc(100% - 374px);
  height: calc(100vh - 65px);
  overflow-y: auto;
  :global {
    .ant-tabs > .ant-tabs-nav {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.dotActive {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background: $green;
  margin-right: 5px;
}

.dotUnActive {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background: $red;
  margin-right: 5px;
}

.infoVendor {
  margin-bottom: 10px;
}

.changePass {
  font-weight: bold;
  color: $blue2;
  font-size: 13px;
}

.iconLeft {
  filter: grayscale(1);
  transform: rotate(-90deg);
}

.linkTabWork {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: $background;
  border: 1px solid $line;
  padding: 9px 10px;
}

.boxAvatarEdit {
  position: relative;
  width: 130px;
  height: 130px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  :global {
    .ant-upload-list-text-container {
      display: none;
    }
  }
}

.avatar {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  object-fit: cover;
}

.iconUpdoadImage {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.disableVendor {
  opacity: 0.5;
}

.onlyIcon {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  object-fit: cover;
  background-color: $background;
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 56px;
    transform: translate(-50%, -50%);
  }
}
.addField {
  position: absolute;
  top: 30px;
  right: 0;
  cursor: pointer;
}

.wrapContent {
  position: relative;
  margin-left: 12px;
  margin-right: 12px;
}

.wrapperUnitPrice {
  width: 50%;
  .unitPrice {
    margin-left: 0 !important;
    width: 100%;
  }
  .icon {
    padding-left: 3px !important;
    top: 34px;
    cursor: pointer;
  }
}

.wrapper {
  justify-content: space-between !important;
}
.inputNumber {
  border-radius: 3px;
  border: none;
}
.selectCheck {
  font-weight: 700;
  color: $text;
  font-size: 14px;
  :global {
    .ant-checkbox-inner {
      background-color: #f8f8f8;
      border: 1px solid #f0f0f0;
      border-radius: 3px;
    }
  }
}

.customSelect {
  :global {
    .ant-select-selector {
      background-color: $line !important;
      border: none !important;
    }
  }
}

.dataUnitPrice {
  .dataUnitItem {
    width: 50%;
  }
}
.commonButton {
  margin-left: 28px;
  margin-bottom: 16px;
  width: 82%;
  background-color: #f0f0f0;
  border: none;
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  color: #757575;
  outline: none;
  &:hover,
  &:focus {
    background-color: $line;
    color: $text2;
  }
  img {
    margin-right: 8px;
    margin-top: -3px;
    height: 18px;
  }
}
.textBlue {
  color: $blue2;
}
