@import 'styles/variables.scss';

.container {
  :global {
    .ant-select-selector {
      background-color: $background !important;
      border-radius: 4px !important;
    }
  }
}
.option {
  display: flex;
  align-items: center;
  .info {
    margin-left: 10px;
  }
}

.itemVendorPickerWrap {
  display: flex;
  gap: 8px;
  align-items: flex-end;
  width: 100%;

  &.alignStart {
    align-items: flex-start;
  }

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.selectedItem {
  background-color: #ffe8e8;
  display: flex;
  margin-top: 5px;
  padding: 10px;
  border-radius: 3px;
  align-items: center;
  min-height: 60px;
  position: relative;
  width: 100%;

  &.unActive {
    background-color: #8e8e8e;
  }

  .info {
    flex: 1;
    margin-left: 10px;
    font-size: 13px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 60px;
  }

  .maxWorker {
    width: 100px;
  }

  .remove {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

:global {
  :local(.inputPrice) {
    &.ant-form-item {
      margin-bottom: 0;
      width: 100px;
    }
  }
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.listSelected {
  margin-top: 10px;
}
