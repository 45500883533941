.container {
  max-width: 1045px;
  margin: 0 auto;
}

.selectStatus {
  flex-direction: row !important;
  align-items: center;
  :global {
    .ant-form-item-label {
      padding-bottom: 0;
      margin-right: 10px;
    }
    .ant-select {
      min-width: 140px;
    }
  }
}

.representativeName {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  width: 100%;
  padding-left: 100px;
  z-index: 10;
}

.btn{
  min-width: 240px;
  margin-right: 12px;
  height: 46px;
}
.modalConfirm{
  margin-top: 50px;
  :global {
    h2 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 50px;
    }
    .ant-modal-body {
      padding: 1px 100px 50px 100px;
    }
  }
}
.titleModal {
  p{
    margin-bottom: 5px;
  }
}