.pageWrapper {
  display: flex;
  padding-top: 65px;
  height: 100vh;
}
.mainWrapper {
  height: 100%;
  flex: 1;
  width: calc(100% - 100px);
  padding-left: 101px;
  transition: all 0.3s ease 0s;

  &.collapsed {
    padding-left: 61px;
  }
}
.pageContent {
  flex: 1;
  padding: 20px;
  overflow: auto;
  background: #fff;
  height: 100%;
  // height: calc(100vh - 65px);
}
