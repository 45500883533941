@import '../../styles/variables.scss';

.calendar {
  background: #f8f8f8;

  :global {
    .rbc-date-cell {
      text-align: left;
      padding: 0 5px;
    }
    .rbc-day-bg.selecting-date,
    .rbc-day-slot.selecting-date {
      background-color: #eaf4fc;
    }
    .rbc-event {
      overflow: auto;
    }
    .rbc-event::-webkit-scrollbar {
      width: 0px;
    }

    .rbc-event,
    .rbc-day-slot .rbc-background-event {
      background-color: $blue3;
      color: $blue1;
      font-weight: bold;
      // overflow-x: hidden;
      // overflow-y: auto;
      overflow: visible;

      // position: relative;

      ::-webkit-scrollbar {
        width: 0px;
      }
    }
    .rbc-event-label {
      display: none;
    }
    .rbc-show-more {
      color: $blue1;
    }
    .rbc-day-slot .rbc-event,
    .rbc-day-slot .rbc-background-event {
      border: none;

      ::-webkit-scrollbar {
        width: 0px;
      }
    }
    .rbc-today {
      background-color: #f8f8f8;
    }
    .rbc-header {
      padding: 16px 5px 5px 5px;
      border-left: none;
      background-color: #fff;
    }
    .rbc-day-bg {
      border-left: none;
    }
    .rbc-allday-cell {
      display: none;
    }
    .rbc-time-gutter .rbc-time-slot .rbc-label {
      background: #f8f8f8;
      display: block;
      transform: translateY(-12px);
    }
    .rbc-time-gutter .rbc-timeslot-group:first-child {
      visibility: hidden;
    }
    .rbc-time-content {
      border-top: none;
    }
    .rbc-show-more {
      text-align: center;
    }
    .rbc-time-column:not(.rbc-time-gutter) {
      .rbc-time-slot {
        border-top-color: transparent;
      }
      .rbc-timeslot-group {
        background-color: #f0f8ff;
        border-bottom-color: #f0f8ff;
      }
    }

    // distance between events
    .rbc-timeslot-group {
      min-height: var(--height-row);
    }
  }

  &:global {
    &.viewModeMonth {
      .rbc-event,
      .rbc-day-slot .rbc-background-event {
        position: relative;
      }
    }
  }
}

.avatarGroup {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 5px;

  :global {
    .ant-avatar-group {
      display: flex;
      flex-wrap: wrap;
      overflow: auto;
    }
    .ant-avatar:not(:first-child) {
      margin-left: 0;
    }
  }
}

.containerNote {
  color: #fff;
  height: 100%;
  border-radius: 4px;
  padding: 5px 5px 0 5px;
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 700;
  }

  .note {
    font-weight: 400;
  }

  .iconFileNote {
    width: 14px;
    margin-top: 5px;
  }

  .contentNoteDay {
    flex: 1;
  }
}

.iconFileNoteTooltip {
  width: 14px;
}

.noteTask {
  padding: 10px 10px 10px 0;
  display: grid;
  grid-template-columns: 20px 1fr;
  color: $black;
}

.contentTaskDay {
  color: $black;
}

.description {
  display: grid;
  grid-template-columns: 20px 10px 1fr;
  margin-top: 10px;
}

.borderEvent {
  position: absolute;
  width: 4px;
  height: 100%;
  border-radius: 5px;
  top: 0;
  left: 0;
  bottom: 0;
}

.borderEventDay {
  left: 1px;
}

.borderEventWorkerReport {
  height: calc(100% - 8px);
  width: 5px;
  top: 50%;
  transform: translateY(-50%);
  left: 2px;
}

.borderNoteDay {
  left: 0.5px;
}

.groupAva {
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
  color: black;
}

.wrapperAvatar {
  display: grid;
  place-items: center;
  width: 60px;
  gap: 4px;

  .name {
    font-size: 14px;
    font-weight: 400;
  }
}

.label {
  flex-shrink: 0;
}

.actionsTaskDay {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-left: auto;
}

.leaderZone {
  // padding: 10px 10px 10px 0;
  // border-radius: 10px;
  margin-top: 10px;
  border: 2px dashed transparent;
  transition: all 0.2s;

  &.canDropLeader {
    border: 2px dashed $blue1;
  }

  &.activeLeaderZone {
    border: 2px solid $blue1;
    background-color: $blue1;
    color: white;
  }
}

.avatarWrapper {
  position: relative;

  .isLeader {
    position: absolute;
    width: 14px;
    aspect-ratio: 1/1;
    cursor: pointer;
    left: -1px;
    top: 0px;
    z-index: 1;
  }
}

.canDropWorker {
  border: 2px dashed #2b88d8;
}

.titleTaskDay {
  // width: fit-content;
}

.tooltipDay {
  :global {
    min-width: 400px;
  }
}

.wrapperTaskDay {
  position: absolute;
  inset: 0;
  padding: 5px 0 0 5px;
  overflow: auto;

  display: flex;
  flex-direction: column;

  .contentTaskDay {
    flex: 1;
  }
}

/* --------------------------- Icon files calendar -------------------------- */
.filesWrap {
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;
  gap: 2px;
  width: max-content;

  position: sticky;
  left: 100%;
  right: 0;
  bottom: 3px;
}
