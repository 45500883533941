@import '../../styles/variables.scss';
.sideNav {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 65px);

  /* --------------- Hide scrollbar for Chrome, Safari and Opera -------------- */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  :global {
    .ant-menu {
      background-color: transparent;
      text-align: center;
      border: none !important;
    }
    .ant-menu-item {
      display: flex;
      flex-direction: column;
      margin: 0;
      height: 68px;
      line-height: 20px;
      justify-content: center;
      padding: 0 !important;
      &.ant-menu-item-selected {
        background-color: #1c6cb2;
      }
      &.ant-menu-item-selected::after {
        left: 0;
        right: initial;
        border-right: 6px solid $blue2;
      }
      & a {
        font-size: 11px;
      }
    }
    .ant-menu-item-icon {
      margin: 0;
      height: 24px;
    }
    .ant-menu-item a {
      color: $white !important;
    }
  }
}

.badge {
  :global {
    .ant-badge-count {
      top: -24px;
      min-width: 15px;
      height: 15px;
      font-size: 10px;
      line-height: 15px;
      border-radius: 7px;
      min-width: 15px;
      padding: 0 5px;
    }
  }
}

.linkItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 13px;
  position: relative;

  &.active {
    background-color: rgba($color: #2b88d8, $alpha: 0.5);

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 6px;
      height: 100%;
      background-color: #2b88d8;
    }
  }

  .icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  .textLink {
    color: white;
    font-size: smaller;
    text-align: center;
    margin-top: 4px;
  }
}

.wrapper {
  background: $blue1;
  border-right: 1px solid $blue2;
  height: calc(100vh - 65px);
  position: fixed;
  z-index: 101;
  width: 100px;
  transition: all 0.3s ease 0s;

  .buttonCollapseSideBar {
    display: flex;
    cursor: pointer;
    padding: 10px 0px;
    justify-content: flex-end;
    border-bottom: 1px solid #4c6a74;
    margin: 0 10px;
  }
  .buttonCollapsedSideBar {
    cursor: pointer;
    justify-content: center;
    padding: 10px 0px;
    border-bottom: 1px solid #4c6a74;
    margin: 0 10px;
  }

  &.collapsed {
    width: 60px;

    .buttonCollapse {
      transform: translateX(calc(-50%)) rotate(180deg);
    }

    .badge,
    .textLink {
      display: none;
    }
  }
}
