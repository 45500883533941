.wrapper {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1005;
  padding: 16px;
}

.myModal {
  min-width: 1200px;
}
