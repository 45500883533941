@import 'styles/variables.scss';

.boxUpload {
  border: 1px solid #75757582;
  border-radius: 4px;
  position: relative;
  padding: 16px 10px;
  background: #80808017;
  display: flex;
  justify-content: flex-end;
  .picker {
    position: absolute;
    inset: 0;
    cursor: pointer;

    .wrapperInput {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
    }
  }
}

.fileList {
  margin-top: 5px;
  .fileItem {
    max-width: 100%;
    display: flex;
    // justify-content: space-between;
    background-color: $blue3;
    padding: 3px 5px;
    margin-bottom: 5px;
    gap: 10px;

    a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .icon {
    width: 20px;
    cursor: pointer;
    margin-left: auto;
  }
}

.dragMessage {
  position: absolute;
  top: 50%;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  height: 100%;
  border: 1px dashed black;
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
