.img {
  width: 25px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  &:hover,
  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
  }

  :global {
    .ant-btn[disabled] {
      border: none;
      background-color: transparent;
      width: 25px;
      height: 25px;
    }

    img {
      width: 25px;
      height: 25px;
    }
  }
}
