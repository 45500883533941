.customButton {
  display: flex;
  gap: 0px;
  align-items: center;
  cursor: pointer;
  gap: 4px;

  .titleUnderline {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: #2d2d2d;
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}

.modalSendEmail {
  :global {
    .ant-modal-body {
      padding: 0 24px;
    }
  }
}

.linkPdf {
  color: #2388ff;
}
