@import '../../styles/variables.scss';

.profile {
  display: flex;
  padding: 4px 0;
  margin-right: 15px;
  align-items: center;
  height: 100%;

  .avatar {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    position: relative;
    flex-shrink: 0;
    .avatarText {
      color: rgb(245, 106, 0);
      background-color: rgb(253, 227, 207);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      text-align: center;
      line-height: 20px;
      b {
        text-transform: uppercase;
      }
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      position: absolute;
    }
    :global {
      .ant-avatar {
        width: 100%;
        height: 100%;
        line-height: 21px;
        margin-top: -4px;
      }
    }
  }
  .infoprofile {
    line-height: 1.3;
    :global {
      span.ant-typography {
        font-size: 13px;
        color: $blue2;
      }
      h5 {
        margin-bottom: 0;
        line-height: 16px;
        font-size: 14px;
        color: $blue1;
      }
    }
  }

  .nameRoleInCompany {
    margin-left: 10px;
    color: $blue1;
    font-weight: 600;
  }
}
