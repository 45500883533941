@import '../../styles/variables.scss';
.datepicker {
  width: 100%;
  background-color: $line !important;
  border-radius: 4px;

  :global {
    .ant-picker-suffix {
      font-size: 16px;
      color: $inactive;
    }
    .ant-picker-clear {
      font-size: 16px;
      background-color: $line;
    }
  }
}

.hideIcon {
  :global {
    .ant-picker-suffix {
      display: none;
    }
  }
}
