@import '../../styles/variables.scss';
.input {
  height: 32px;
  width: 100%;
  background-color: $line !important;
  border-radius: 4px;
  position: relative;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none !important;
  border-color: #d9d9d9 !important;
}

.filePicker {
  position: relative;
  background-color: $line;
  padding: 10px;
  border: 1px #d9d9d9 solid;
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  min-height: 50px;
  .fileList {
    .fileItem {
      max-width: 100%;
      width: 300px;
      display: flex;
      justify-content: space-between;
      background-color: $blue3;
      padding: 3px 5px;
      margin-bottom: 5px;
      a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .icon {
      width: 20px;
      cursor: pointer;
    }
  }
  .picker {
    position: absolute;
    bottom: 5px;
    right: 5px;
    img {
      margin-left: 20px;
      cursor: pointer;
    }
  }
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;

  :global {
    textarea.ant-input {
      color: #00000040;
    }
  }
}

.drag {
  // opacity: 50%;
  position: relative;
}

.dragMessage {
  position: absolute;
  top: 50%;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  height: 100%;
  border: 1px dashed black;
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dragMessageItem {
  padding: 10px 50px;
  background-color: #fff;
}
