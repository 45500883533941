@import '../../styles/variables.scss';
.modal {
  :global {
    .ant-modal-content {
      border-radius: 3px;
      overflow: hidden;
      box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.15);
    }

    .ant-modal-header {
      padding: 16px 50px;

      .ant-modal-title {
        font-size: 20px;
        font-weight: 700;
      }
    }

    .ant-modal-body {
      padding: 10px 50px 16px 50px;
    }

    // .ant-modal-body {
    //   padding: 50px 100px;
    // }

    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      border-top: 0;
      padding-bottom: 24px;

      .ant-btn {
        width: 133px;
        border-radius: 3px;
        font-weight: 700;
        font-family: 'Roboto';

        &:first-child {
          border: 1px solid #2b88d8;
          color: #2b88d8;
        }
      }
    }
  }

  &.centerTitle {
    :global {
      .ant-modal-header {
        text-align: center;
      }
    }
  }
}

.resetPadding {
  :global {
    .ant-modal-header {
      padding: 16px 24px;
    }
    .ant-modal-body {
      padding: 24px;
    }
  }
}
