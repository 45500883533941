@import 'styles/variables.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.modal {
  :global {
    .ant-modal-content,
    .ant-modal-header {
      background-color: #f8f8f8;
    }

    .ant-modal-body {
      padding: 10px 24px 16px;
    }
  }

  .colWrap {
    background: white;
    padding: 10px;
  }
}

.listFiles {
  display: flex;
  flex-direction: column;
}

.accordionShift {
  background-color: #dbeeff;
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  :global {
    .ant-collapse-header {
      font-weight: bold;
      color: $blue2;
    }
  }
}

.icons {
  width: 120px;
  background-color: #f0f0f0;
  border: none;
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  color: #f0f0f0;
  margin-left: 15px;
  outline: none;

  img {
    margin-right: 8px;
    margin-top: -3px;
    height: 18px;
  }
}

.button {
  text-align: left;
  padding-left: 45px;
}

.listItem {
  :hover {
    // background-color: #2b88d8;
    color: white;
  }

  img {
    margin-right: 8px;
  }
}

.textArea {
  background-color: #f1eeee;
  border-radius: 5px;
  min-height: 150px;
}

.btnReject {
  background: white;
  border: 1px solid $red;
  color: $red;
  width: 100px;
}

.btnAccept {
  background: white;
  border: 1px solid $green;
  color: $green;
  width: 100px;
  justify-content: end;
  margin-left: 3%;
}

.btnAccept2 {
  background: white;
  color: white;
  width: 100px;
  justify-content: end;
  margin-left: 3%;
}

.greenCheckbox {
  color: #39bb24;

  :global {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #39bb24;
      border-color: #39bb24;
    }
  }
}

.customButton {
  display: flex;
  gap: 0px;
  align-items: center;
  cursor: pointer;
  gap: 4px;

  .titleUnderline {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: #2d2d2d;
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}

.btnCreate {
  border-radius: 3px !important;
  margin-left: 24px;
  margin-right: 24px;

  .linkCreate {
    font: inherit;
    color: inherit;
  }
}

.certificateCustomer {
  display: flex;

  .title {
    width: 100px;
    color: #ff0000;
  }
}

.metaDataCertificateCustomer {
  .item {
    display: flex;
    color: #39bb24;

    .title {
      width: 200px;
    }

    .certificates {
      display: flex;
      flex-direction: column;
    }
  }
}
