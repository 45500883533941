@import '../../styles/variables.scss';

.avatarText {
  background-color: $blue2;
  color: $white;
  text-transform: uppercase;
}

.wrapper {
  flex-shrink: 0;
  position: relative;
  display: inline-block;
}

.icClose {
  position: absolute;
  top: -2px;
  right: 0px;
  width: 16px;
  cursor: pointer;
}

.avatarVendor {
  border: 1px solid red !important;
}
